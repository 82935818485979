<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ item.app_text || item.category_name }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "ListItemTitleCategory",
  props: ["item"],
};
</script>
